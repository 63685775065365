import { IncomingMessage } from 'http';

import { fechFromBPartsApi } from '../utils';

export const fetchLoggedUser = async (
  {
    locale,
  }: {
    locale: string;
  },
  req?: IncomingMessage
): Promise<TLoggedUser | null> => {
  const auth = await fechFromBPartsApi(`/${locale}/shop/accounts/logged-user`, {
    method: 'GET',
    credentials: 'include',
    headers: req ? new Headers(req.headers as HeadersInit) : undefined,
  });

  if (auth.ok) return auth.json();
  else return null;
};

export default fetchLoggedUser;
