import cx from 'classnames';
import useTranslation from 'next-translate/useTranslation';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { FunctionComponent, useMemo } from 'react';

import useLinkProps from '../../../hooks/use-link-props';
import { categoryCounterReducer } from '../../../utils/category-counter-reduce';
import { useAppContext } from '../../providers/app-context';
import CategoryMask from '../../ui/categories/svg-image/with-text';
import DividerBottom from '../../ui/icons/dividers/bottom';
import DividerTop from '../../ui/icons/dividers/top';
import BPImage from '../../ui/image';
import FeatureLink from '../../ui/links/featured';

import classes from './categories.module.css';

const Support: FunctionComponent<{ groups: TCategories }> = ({ groups }) => {
  const { locale } = useRouter();
  const {
    features: { isBrandEnabled },
    brand,
  } = useAppContext();
  const { t } = useTranslation();
  const getLink = useLinkProps();

  const totals = useMemo(() => categoryCounterReducer(groups), [groups]);

  return (
    <section className={cx('overflow-hidden')} data-cy="categories">
      <DividerTop className={cx('text-neutral-10', 'w-full', 'lg:h-16')} />
      <div className={cx('bg-neutral-10', 'py-9', 'lg:pt-9', 'lg:pb-20')}>
        <div className="container m-auto text-center">
          <h2 className="text-2xl font-bold">{t('home:categories.title')}</h2>
          <p className="text-base font-light">{t('home:categories.text')}</p>

          <ul
            className={cx(
              'mt-10',
              'grid',
              'lg:grid-cols-4',
              'gap-6',
              'px-6 lg:px-0'
            )}
          >
            {groups.map((category, index) => {
              // hide other from homepage
              if (category.id === 3) return null;

              let total = 0;

              return (
                <li
                  data-line={Math.round(index / 2)}
                  key={category.name}
                  className={cx(
                    classes.linearBackgroundParent,
                    'group',
                    'relative',
                    'pb-[100%]'
                  )}
                >
                  <BPImage
                    src={`${process.env.PUBLIC_PREFIX}/img/categories/${category.id}.jpg`}
                    className={cx(classes.categoryMask, 'w-full z-0')}
                    alt={category.name}
                    layout="fill"
                    objectFit="cover"
                  />
                  <div
                    className={cx(
                      'absolute',
                      'w-full',
                      'h-full',
                      'text-left',
                      'text-white',
                      'flex',
                      'flex-col',
                      'justify-end'
                    )}
                  >
                    <div
                      className={cx(
                        'relative',
                        classes.linearBackground,
                        'pt-[50px]',
                        'group-hover:pt-4',
                        'px-4',
                        'pb-4',
                        'transition-all',
                        'duration-500',
                        'ease-out',
                        'overflow-hidden'
                      )}
                    >
                      <h3 className="text-2xl">{category.name}</h3>
                      <span>
                        {t('home:categories.number.other', {
                          count: totals[category.slug].toLocaleString(locale),
                        })}
                      </span>
                    </div>

                    <ul
                      className={cx(
                        '',
                        'relative',
                        'w-full',
                        'bg-[#000]/75',
                        'flex',
                        'flex-col',
                        'gap-2',
                        'px-4',
                        'h-0',
                        'pb-0',
                        'pt-0',
                        'group-hover:pt-4',
                        'overflow-hidden',
                        'group-hover:h-[100%]',
                        'group-hover:pb-4',
                        'transition-all',
                        'duration-500',
                        'ease-out	'
                      )}
                    >
                      {category?.parts &&
                        Object.keys(category.parts)?.map((key) => {
                          return category.parts[key].map((part) => {
                            if (total > 4) return;

                            total++;
                            return (
                              <li key={part.name}>
                                <Link
                                  {...getLink(
                                    isBrandEnabled
                                      ? 'models-with-part'
                                      : 'brands-with-part',
                                    {
                                      category: category.slug,
                                      subCategory: part.slug,
                                      ...(isBrandEnabled && { brand }),
                                    }
                                  )}
                                  passHref
                                  scroll
                                  shallow
                                  legacyBehavior
                                >
                                  <FeatureLink variant="white">
                                    {part.name}
                                  </FeatureLink>
                                </Link>
                              </li>
                            );
                          });
                        })}
                      <li
                        className={cx(
                          'font-bold',
                          'grow',
                          'flex',
                          'justify-end',
                          'items-end'
                        )}
                      >
                        <Link
                          {...getLink('sub-categories', {
                            category: category.slug,
                          })}
                          passHref
                          scroll
                          shallow
                          legacyBehavior
                        >
                          <FeatureLink variant="white" data-cy="list-all-link">
                            {t('home:categories.list-all')}
                          </FeatureLink>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </li>
              );
            })}
            <li
              className={cx(
                'lg:col-span-4',
                'lg:text-center',
                'pl-4 lg:pl-0',
                'mt-8 lg:mt-4',
                'lg:flex',
                'lg:flex-col',
                'lg:justify-end',
                'lg:items-center',
                'lg:text-xl'
              )}
            >
              <div className={cx('font-bold')}>
                {t('home:categories.pre-link')}
              </div>
              <div>
                <Link
                  {...getLink('categories')}
                  passHref
                  scroll
                  shallow
                  legacyBehavior
                >
                  <FeatureLink className="lg:font-normal">
                    {t('home:categories.link')}
                  </FeatureLink>
                </Link>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <DividerBottom className={cx('text-neutral-10', 'w-full', 'lg:h-7')} />
    </section>
  );
};

export default Support;
