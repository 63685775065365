import cx from 'classnames';
import { FunctionComponent } from 'react';

import BrandShape from '../icons/brand-shape';

import classes from './icon-shape.module.css';

export type IconBlockProps = {
  isAbsolute?: boolean;
  iconSrc: string;
  color?: string;
};

const IconShape: FunctionComponent<IconBlockProps> = ({
  isAbsolute = true,
  iconSrc,
  color,
}) => (
  <div className={cx({ absolute: isAbsolute }, 'relative', 'h-12', 'w-12')}>
    <BrandShape
      className={cx(
        '',
        classes.itemCenter,
        'relative',
        'block',
        color ?? 'text-neutral-10'
      )}
    />
    <img className={cx(classes.itemCenter, 'absolute')} src={iconSrc} />
  </div>
);

export default IconShape;
