import cx from 'classnames';
import Trans from 'next-translate/Trans';
import { FunctionComponent } from 'react';

import { IconStar, IconHalfStar } from '../../ui/icons/start';
import BPImage from '../../ui/image';

import classes from './trustpilot.module.css';

const Trustpilot: FunctionComponent<{
  className?: string | undefined;
  backgroundColor?: string;
}> = ({ className, backgroundColor }) => {
  const count = '+8000';
  const value = 4.8;

  return (
    <>
      <div
        className={cx(
          'flex',
          'justify-center',
          'items-center',
          className,
          backgroundColor ? backgroundColor : 'bg-neutral-10'
        )}
      >
        <span className={'font-bold mx-1'}>{value}</span>
        <div className={cx('flex', classes.starsContainer)}>
          {[0, 1, 2, 3].map((_, index) => (
            <IconStar
              // eslint-disable-next-line react/no-array-index-key
              key={`google-star${index}`}
              className={cx(
                'h-4',
                'mr-1',
                value >= index + 1 ? 'text-bparts-100' : 'text-white'
              )}
            />
          ))}
          <IconHalfStar className={cx('h-4', 'mr-1', 'text-bparts-100')} />
        </div>
        <Trans
          i18nKey={'common:google-reviews'}
          components={{ strong: <strong className={'mx-1 text-bparts-100'} /> }}
          values={{
            count: count,
          }}
        />
        <span className={'hidden lg:block mr-1'}>Google Reviews</span>
        <BPImage
          src={`${process.env.PUBLIC_PREFIX}/svg/icons/google.svg`}
          className={'mx-1 h-6'}
          height={24}
          width={32}
          layout="fixed"
          alt="Google Reviews"
        />
      </div>
    </>
  );
};

export default Trustpilot;
