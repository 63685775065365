import cx from 'classnames';
import useTranslation from 'next-translate/useTranslation';
import { FunctionComponent, ReactNode } from 'react';

import formatElements from '../../../utils/formatElements';
import BrandShape from '../../ui/icons/brand-shape';

import classes from './about-us.module.css';

const AboutUs: FunctionComponent = () => {
  const { t } = useTranslation();
  const content = t(
    'home:about-us.content',
    {},
    { returnObjects: true }
  ) as unknown as Array<{ title: string; description: string }>;
  return (
    <section className="py-16 lg:py-20 lg:pb-16 px-6 text-neutral-80">
      <div className="container m-auto">
        <h2
          className={cx(
            classes.title,
            'text-bparts-100 font-bold text-2xl text-center px-6 mb-12 lg:mb-20'
          )}
        >
          {t('home:about-us.title')}
        </h2>
        <ol
          className={cx(
            classes.list,
            'lg:grid lg:grid-cols-3 lg:gap-6 lg:mb-10'
          )}
        >
          {content.map((item, index) => (
            <li
              className={cx(classes.item, 'relative mb-12 lg:mb-0 flex')}
              key={item.title}
            >
              <BrandShape
                className={cx(
                  classes.itemShape,
                  'relative',
                  'block',
                  'text-bparts-100',
                  'h-12',
                  'w-12'
                )}
              />
              <div>
                <h3 className={cx('text-2xl', 'font-bold', 'leading-8')}>
                  {item.title}
                </h3>
                <p className="text-base">
                  {formatElements(item.description, { b: <b /> }) as ReactNode}
                </p>
              </div>
            </li>
          ))}
        </ol>
      </div>
    </section>
  );
};

export default AboutUs;
