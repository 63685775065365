import { FunctionComponent, HTMLAttributes } from 'react';

const BrandShape: FunctionComponent<HTMLAttributes<HTMLOrSVGElement>> = (
  props
) => (
  <svg
    {...props}
    xmlns="https://www.w3.org/2000/svg"
    viewBox="0 0 49 49"
    fill="none"
  >
    <path
      d="M24.9728 1.03957C33.2985 -1.1913 41.8563 3.74955 44.0872 12.0753L47.5431 24.9728C49.7739 33.2985 44.8331 41.8563 36.5074 44.0872L23.6098 47.5431C15.2841 49.7739 6.72632 44.8331 4.49545 36.5074L1.03957 23.6098C-1.1913 15.2841 3.74955 6.72632 12.0753 4.49545L24.9728 1.03957Z"
      fill="currentColor"
    />
  </svg>
);

export default BrandShape;
