import cx from 'classnames';
import useTranslation from 'next-translate/useTranslation';
import { FunctionComponent } from 'react';

import { IconStar } from '../../ui/icons/start';

import classes from './reviews.module.css';

const numberStarts = [undefined, undefined, undefined, undefined, undefined];

type TReview = {
  name: string;
  value: number;
  comment: string;
};
const Review: FunctionComponent<TReview> = ({ name, value, comment }) => (
  <figure className="block">
    <figcaption className="flex flex-1 justify-between">
      <span className="flex flex-1 mb-6">
        {numberStarts.map((_, index) => (
          <IconStar
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            className={cx(
              'h-4',
              'mr-1',
              value >= index + 1 ? 'text-bparts-100' : 'text-white'
            )}
          />
        ))}
      </span>
      <cite className="text-sm text-neutral-50">{name}</cite>
    </figcaption>
    <blockquote className={cx(classes.quote, 'flex flex-1 italic font-light')}>
      {comment}
    </blockquote>
  </figure>
);

const Reviews: FunctionComponent = () => {
  const { t } = useTranslation();
  const items = t('common:reviews.items', undefined, {
    returnObjects: true,
  }) as unknown as Array<TReview & { id: string }>;

  return (
    <section className={cx(classes.reviews)}>
      <div
        className={cx(
          classes.header,
          'container pt-14 lg:pt-20 lg:pb-20 mx-auto'
        )}
      >
        <div className={cx('pb-12 px-6 text-center bg-white')}>
          <h2 className="text-2xl font-bold px-6">
            {t('common:reviews.title')}
          </h2>
          <p className="text-base px-6">{t('common:reviews.sub-title')}</p>
        </div>

        <ul
          className={cx(
            'px-6',
            'lg:px-0',
            'lg:grid',
            'lg:grid-cols-3',
            'lg:gap-20'
          )}
        >
          {items.map((item) => (
            <li
              key={item.id}
              className={cx(
                classes.item,
                'pb-8 mb-8 border-b-2 border-neutral-10 lg:pb-6 lg:mb-6'
              )}
            >
              <Review {...item} />
            </li>
          ))}
        </ul>
      </div>
    </section>
  );
};

export default Reviews;
