import React from 'react';

const useOGMeta = ({
  title,
  type = 'website',
  url,
  image = 'https://s3-eu-west-1.amazonaws.com/bparts-eu/logo/b-parts-brand-logo.png',
  description,
  sitename = 'B-Parts',
}: {
  title: string;
  type?: string;
  url: string;
  image?: string;
  description: string;
  sitename?: string;
}) => (
  <>
    <meta key="og:title" property="og:title" content={title} />
    <meta key="og:type" property="og:type" content={type} />
    <meta key="og:url" property="og:url" content={url} />
    <meta key="og:image" property="og:image" content={image} />
    <meta
      key="og:description"
      property="og:description"
      content={description}
    />

    <meta key="og:site_name" property="og:site_name" content={sitename} />
  </>
);

export default useOGMeta;
