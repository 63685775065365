import { FunctionComponent, HTMLAttributes } from 'react';

export const IconStar: FunctionComponent<HTMLAttributes<HTMLOrSVGElement>> = (
  props
) => (
  <svg
    {...props}
    xmlns="https://www.w3.org/2000/svg"
    viewBox="0 0 18 17"
    fill="none"
  >
    <path
      d="M17 6.71429H10.8571L9 1L7.14286 6.71429H1L6 10.1429L4.07143 15.8571L9 12.2857L13.9286 15.8571L12 10.1429L17 6.71429Z"
      fill="currentColor"
      stroke="#359AD2"
      strokeLinejoin="round"
    />
  </svg>
);

export const IconHalfStar: FunctionComponent<
  HTMLAttributes<HTMLOrSVGElement>
> = (props) => {
  return (
    <svg
      {...props}
      xmlns="https://www.w3.org/2000/svg"
      viewBox="0 0 18 17"
      fill="none"
    >
      <defs>
        <linearGradient id="half_grad">
          <stop offset="50%" stopColor="currentColor" />
          <stop offset="50%" stopColor="#FFFFFF" stopOpacity="1" />
        </linearGradient>
      </defs>

      <path
        d="M17 6.71429H10.8571L9 1L7.14286 6.71429H1L6 10.1429L4.07143 15.8571L9 12.2857L13.9286 15.8571L12 10.1429L17 6.71429Z"
        fill="url(#half_grad)"
        stroke="#359AD2"
        strokeLinejoin="round"
      />
    </svg>
  );
};
