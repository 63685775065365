import { useQueries } from '@tanstack/react-query';
import cx from 'classnames';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import React, { FunctionComponent, useMemo } from 'react';

import { fetchFaqs } from '../../../services/faqs';
import { fetchGroups } from '../../../services/groups';
import { fetchBrands } from '../../../services/vehicles';
import Reviews from '../../modules/reviews';
import Trustpilot from '../../modules/trustpilot';
import ValuePropositionV1 from '../../modules/value-proposition';
import ValueProposition from '../../modules/v2/value-proposition';
import { useAppContext } from '../../providers/app-context';
import DividerBottom from '../../ui/icons/dividers/bottom';

import AboutUs from './about-us';
import Banner from './banner';
import Categories from './categories';
import Questions from './questions';
import HomeSchema from './seo';

const TopBrands = dynamic(() => import('./top-brands'));

const Home: FunctionComponent<TPageHome> = () => {
  const { locale, route } = useRouter();
  const {
    features: { isBrandEnabled },
  } = useAppContext();
  const isV1Locale = locale !== 'pt';
  const [{ data: groups }, { data: brands }, { data: faqs }] = useQueries({
    queries: [
      {
        queryKey: ['groups', locale],
        queryFn: ({ queryKey: [, locale] }) => fetchGroups({ locale }),
        staleTime: 10000,
        keepPreviousData: true,
      },
      {
        queryKey: ['brands', locale],
        queryFn: ({ queryKey: [, locale] }) => fetchBrands({ locale }),
        staleTime: 10000,
        keepPreviousData: true,
        enabled: isBrandEnabled,
      },
      {
        queryKey: ['faqs', locale, 'home'],
        queryFn: ({ queryKey: [, locale, slug] }) =>
          fetchFaqs({ locale, slug }),
        staleTime: 10000,
        keepPreviousData: true,
      },
    ],
  });

  const brandList = useMemo(() => {
    if (brands) {
      return brands.children?.filter((b) => b.logo_url && b.logo_white_url);
    }

    return [];
  }, [brands]);

  return (
    <main>
      <HomeSchema />
      <Banner />
      <Trustpilot className={'pt-4'} />
      <DividerBottom className={cx('text-neutral-10')} />
      {isV1Locale ? <ValuePropositionV1 /> : <ValueProposition isHome={true} />}
      <Categories groups={groups} />
      <AboutUs />
      {!isBrandEnabled && <TopBrands brands={brandList} />}
      <Reviews />
      <Questions faqs={faqs} />
    </main>
  );
};

export default Home;
