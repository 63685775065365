import { useQuery } from '@tanstack/react-query';
import { useRouter } from 'next/router';

import getLoggedUser from '../../utils/react-query/query-options/get-logged-user';

const useLoggedUser = () => {
  const { locale = 'en' } = useRouter();
  const { data: user, isError } = useQuery(...getLoggedUser({ locale }), {
    refetchOnWindowFocus: true,
    refetchInterval: 60000,
    refetchOnMount: true,
    cacheTime: Infinity,
    staleTime: Infinity,
  });

  if (isError) {
    return null;
  }

  return user;
};

export default useLoggedUser;
