import cx from 'classnames';
import { FunctionComponent, HTMLAttributes } from 'react';

type TDividerBottom = {
  variantMobile?: 'default' | 'down';
} & HTMLAttributes<HTMLOrSVGElement>;

const DividerBottom: FunctionComponent<TDividerBottom> = ({
  variantMobile,
  className,
  ...props
}) => (
  <div>
    <DividerBottomLg
      {...props}
      className={cx(className, 'hidden', 'lg:block')}
    />
    <DividerBottomXs
      {...props}
      variantMobile={variantMobile}
      className={cx(className, 'lg:hidden')}
    />
  </div>
);

export default DividerBottom;

const DividerBottomXs: FunctionComponent<TDividerBottom> = ({
  variantMobile,
  ...props
}) =>
  variantMobile === 'down' ? (
    <svg
      {...props}
      xmlns="https://www.w3.org/2000/svg"
      viewBox="0 0 375 24"
      fill="none"
      preserveAspectRatio="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0V-0.000196457H375V10.2929C347.445 21.8382 317.121 28.0141 285.356 27.5324L94.0897 24.6317C60.3473 24.12 28.4705 16.149 0 0Z"
        fill="currentcolor"
      />
    </svg>
  ) : (
    <svg
      {...props}
      xmlns="https://www.w3.org/2000/svg"
      viewBox="0 0 375 24"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0V-0.000196457H375V10.2929C347.445 21.8382 317.121 28.0141 285.356 27.5324L94.0897 24.6317C60.3473 24.12 28.4705 16.149 0 0Z"
        fill="currentcolor"
      />
    </svg>
  );

const DividerBottomLg: FunctionComponent<HTMLAttributes<HTMLOrSVGElement>> = (
  props
) => (
  <svg
    {...props}
    xmlns="https://www.w3.org/2000/svg"
    viewBox="0 0 1194 27"
    fill="none"
    preserveAspectRatio="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M984.979 27C1056.05 25.9913 1125.87 19.5299 1194 0V0H-6.00171L925.555 26.5724C932.241 26.7632 938.917 26.9056 945.583 27H984.979Z"
      fill="currentcolor"
    />
  </svg>
);
