import cx from 'classnames';
import { FunctionComponent, HTMLAttributes } from 'react';

type TDividerTop = {
  variantMobile?: 'default' | 'up';
} & HTMLAttributes<HTMLOrSVGElement>;

const DividerTop: FunctionComponent<TDividerTop> = ({
  variantMobile = 'default',
  className,
  ...props
}) => (
  <div className={'flex items-end mb-[-1px]'}>
    <DividerTopLg {...props} className={cx(className, 'hidden', 'lg:block')} />
    <DividerTopXs
      {...props}
      variantMobile={variantMobile}
      className={cx(className, 'lg:hidden')}
    />
  </div>
);

export default DividerTop;

const DividerTopXs: FunctionComponent<TDividerTop> = ({
  variantMobile = 'default',
  ...props
}) =>
  variantMobile === 'up' ? (
    <svg
      {...props}
      viewBox="0 0 375 34"
      fill="none"
      xmlns="https://www.w3.org/2000/svg"
      preserveAspectRatio="none"
    >
      <rect
        width="375"
        height="34"
        transform="matrix(1 0 0 -1 0 34)"
        fill="currentcolor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0.460625V0.000246048H375V6.19836C343.398 19.4068 308.442 25.9536 271.974 24.1673L68.9936 14.2245C44.8079 13.0398 21.6479 8.27258 0 0.460625Z"
        fill="white"
      />
    </svg>
  ) : (
    <svg
      {...props}
      xmlns="https://www.w3.org/2000/svg"
      viewBox="0 0 375 25"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 24.5396V25H375V18.8019C343.398 5.59346 308.442 -0.953338 271.974 0.832986L68.9936 10.7757C44.8079 11.9605 21.6479 16.7277 0 24.5396Z"
        fill="currentcolor"
      />
    </svg>
  );

const DividerTopLg: FunctionComponent<HTMLAttributes<HTMLOrSVGElement>> = ({
  ...props
}) => (
  <svg
    {...props}
    xmlns="https://www.w3.org/2000/svg"
    viewBox="0 0 1194 69"
    fill="none"
    preserveAspectRatio="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M248.91 1.82236e-05C161.995 0.0142656 76.7491 8.38231 -6 24.3918V69H1194V67.8166L352.527 3.92895C317.912 1.30086 283.505 0.00561602 249.355 1.82236e-05C249.207 -6.07512e-06 249.059 -6.07395e-06 248.91 1.82236e-05Z"
      fill="currentcolor"
    />
  </svg>
);
