import { IncomingMessage } from 'http';

import { fechFromBPartsApi } from './utils';

export const fetchFaqs = (
  {
    locale,
    slug,
  }: {
    locale: string;
    slug?: string;
  },
  req?: IncomingMessage
) =>
  fechFromBPartsApi(
    `/${locale}/shop/faqs${slug && `/${encodeURI(slug)}`}`,
    {
      credentials: 'include',
      headers: req ? new Headers(req.headers as HeadersInit) : undefined,
    },
    { enableCustomErrorWrapper: true }
  );
