import cx from 'classnames';
import useTranslation from 'next-translate/useTranslation';
import Link from 'next/link';
import React, { FunctionComponent, Fragment } from 'react';

import useLinkProps from '../../../hooks/use-link-props';
import CollapsibleList from '../../ui/collapsible-list/definition';
import DividerTop from '../../ui/icons/dividers/top';
import FeatureLink from '../../ui/links/featured';

const Questions: FunctionComponent<{ faqs: TFaqsHome }> = ({ faqs }) => {
  const { t } = useTranslation();
  const getLink = useLinkProps();

  return (
    <section>
      <DividerTop className={cx('text-neutral-10', 'w-full', 'lg:h-7')} />
      <div className={cx('bg-neutral-10 py-14 lg:py-20 xl:py-24')}>
        <div className="container mx-auto mb-6">
          <div className={cx('pb-6 lg:pb-12 px-4 lg;px-6 text-center')}>
            <h2 className="text-2xl font-bold px-6">
              {t('home:questions.title')}
            </h2>
            <p className="text-base px-6">{t('home:questions.sub-title')}</p>
          </div>

          <CollapsibleList
            className="px-4 lg:px-0"
            items={faqs.map(({ question, answer }) => ({
              term: question,
              // TODO - Fernando
              //description: formatElements(answer, { p: <p /> }) as ReactNode,
              description: <div dangerouslySetInnerHTML={{ __html: answer }} />,
            }))}
          />
        </div>
        <small
          className={cx(
            'text-base font-light w-full flex flex-col items-center'
          )}
        >
          <div className={cx('mb-4')}>{t('home:questions.pre-link')}</div>
          <Link {...getLink('faqs')} passHref legacyBehavior>
            <FeatureLink>{t('home:questions.link')}</FeatureLink>
          </Link>
        </small>
      </div>
    </section>
  );
};

export default Questions;
