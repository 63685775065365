import { IncomingMessage } from 'http';

import { QueryFunctionContext, QueryKey } from '@tanstack/react-query';

import fetchLoggedUser from '../../../services/auth/logged-user';
import { getLoggedUserKeys } from '../query-keys';

const getLoggedUser = (
  options: {
    locale: string;
  },
  req?: IncomingMessage
): [
  QueryKey,
  (options: QueryFunctionContext) => ReturnType<typeof fetchLoggedUser>
] => [
  getLoggedUserKeys(options),
  ({ queryKey: [, , locale] }: { queryKey: string[] }) =>
    fetchLoggedUser({ locale }, req),
];

export default getLoggedUser;
