export function categoryCounterReducer(groups: TCategories): {
  [key: string]: number;
} {
  if (!groups || Object.keys(groups).length === 0) return {};
  return Object.values(groups).reduce((acc, category) => {
    return {
      ...acc,
      ...{ [category.slug]: category.total_products },
    };
  }, {});
}
